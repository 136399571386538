import { redirect } from "react-router-dom";

export const firstLoader = () => {
  console.log("firstLoader");
  const token = sessionStorage.getItem("token");
  if(!token) {
    window.location.href = "https://sso.ayamedica.com/";
  }
}

export function orgLoader() {
  // debugger
  const token = sessionStorage.getItem("token");
  const generalInfo = sessionStorage.getItem("generalInfo");
  const language = sessionStorage.getItem("language");
  const incomingToken = document.location.search.split('?')[1]?.split('token=')[1];

  if (!language) {
    sessionStorage.setItem("language", "en");
  }

  // debugger 
  if (!token && !incomingToken) {
    const redirectURL = generalInfo?.ayamedicaSSOURL ? generalInfo?.ayamedicaSSOURL : "https://sso.ayamedica.com/";
    // const redirectURL = generalInfo?.ayamedicaSSOURL ? generalInfo?.ayamedicaSSOURL : "http://localhost:3002";
    window.location.href = redirectURL; 
    return null;
  }
  else {
    return null;
  }
}

export function billingLoader() {
  // debugger
  const token = sessionStorage.getItem("token");
  const organization = sessionStorage.getItem("organization");
  const pathname = window.location.pathname;
  // debugger
  if (token && pathname === '/billing' && organization && JSON.parse(organization).subscriptionId && (JSON.parse(organization).role === 'PROVIDER_OWNER' || JSON.parse(organization).role === 'OWNER') && JSON.parse(organization).isFreeTrial === false) {
    return null;
  }
  if (token && pathname === '/billing' && organization && JSON.parse(organization).subscriptionId && ((JSON.parse(organization).role !== 'PROVIDER_OWNER' || JSON.parse(organization).role === 'OWNER') || JSON.parse(organization).isFreeTrial === true)) {
    return redirect("/home");
  }
  else if (!token && pathname === '/billing') {
    return redirect("/auth")
  }
  else {
    return null;
  }
}