import { createSlice } from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    notification: 0,
    openBillingOnly: false,
    branchUpdated: false,
    studentsFilters: JSON.parse(sessionStorage.getItem("studentsFilters")) || {
      diseaseNames: [],
      diseasesTypes: [],
      gradeKeys: [],
      subGroupsIds: [],
      minMedicalRecord: null,
      maxMedicalRecord: null
    },
    studentsDetailsFilter: JSON.parse(sessionStorage.getItem("studentsDetailsFilters")) || {
      gradeKeys: [],
      subGroupsIds: [],
      nationality: [],
      gender: null
    },
    feedbackRespondersFilters: JSON.parse(sessionStorage.getItem("feedbackRespondersFilters")) || {
      startDate: "",
      endDate: "",
      rate: null
    },
    studentRecordsDetailsFilters: JSON.parse(sessionStorage.getItem("studentRecordsDetailsFilters")) || {
      endDate:null,
      startDate: null
    },
    branchRoles: JSON.parse(sessionStorage.getItem("branchRoles")) || [],
    branchRolesLoading: false,
    allBranchesRoles: []
  },
  reducers: {
    saveBranchUpdated: (state, action) => {
      state.branchUpdated = action.payload;
    },
    saveOpenBillingOnly: (state, action) => {
      state.openBillingOnly = action.payload;
    },
    clearOpenBilling: (state, action) => {
      state.openBillingOnly = false;
    },
    saveFeedbacksFilter: (state, action) => {
      state.feedbackRespondersFilters = action.payload;
      sessionStorage.setItem("feedbackRespondersFilters", JSON.stringify(action.payload))
    },
    clearFeedbackFilters: (state) => {
      state.feedbackRespondersFilters = {
        startDate: "",
        endDate: "",
        rate: null

      }
      sessionStorage.removeItem("feedbackRespondersFilters")
    },
    saveStudentsDetailsFilter: (state, action) => {
      state.studentsDetailsFilter = action.payload;
      sessionStorage.setItem("studentsDetailsFilters", JSON.stringify(action.payload))
    },
    clearStudentsDetailsFilter: (state, action) => {
      state.studentsDetailsFilter = {
        gradeKeys: [],
        subGroupsIds: [],
        nationality: [],
        gender: null
      }
      sessionStorage.removeItem("studentsDetailsFilters")
    },
    saveStudentsFilter: (state, action) => {
      state.studentsFilters = action.payload;
      sessionStorage.setItem("studentsFilters", JSON.stringify(action.payload))
    },
    clearStudentsFilters: (state) => {
      state.studentsFilters = {
        diseaseNames: [],
        diseasesTypes: [],
        gradeKeys: [],
        subGroupsIds: [],
        minMedicalRecord: null,
        maxMedicalRecord: null
      }
      sessionStorage.removeItem("studentsFilters")
    },
    saveStudentRecordsDetailsFilter: (state, action) => {
      state.studentRecordsDetailsFilters = action.payload;
      sessionStorage.setItem("studentRecordsDetailsFilters", JSON.stringify(action.payload))
    },
    clearStudentRecordsDetailsFilter: (state, action) => {
      state.studentRecordsDetailsFilters = {
        endDate:null,
        startDate: null
      }
      sessionStorage.removeItem("studentRecordsDetailsFilters")
    },
    saveBranchRoles: (state, action) => {
      state.branchRoles = action.payload;
      sessionStorage.setItem("branchRoles", JSON.stringify(action.payload))
    },
    saveBranchRolesLoading: (state, action) => {
      state.branchRolesLoading = action.payload
    },
    saveAllBranchesRoles: (state, action) => {
      state.allBranchesRoles = action.payload
    }
  },
});

export const layoutReducer = layoutSlice.reducer;
export const { saveBranchRoles, saveBranchRolesLoading, saveAllBranchesRoles } = layoutSlice.actions;
