import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  activeNavbarBg: "#0b70fb",
  activeSidebarBg: "#ffffff",
  activeMode: "light",
  activeTheme: "ORANGE_THEME",
  sidebarWidth: 240,
  tableColor: "#ffffff",
};

export const customizerSlice = createSlice({
  name: "customizer",
  initialState,
  reducers: {
    setNavbarBg: (state, action) => {
      state.activeNavbarBg = action.payload;
    },
    setDarkTheme: (state, action) => {
      state.activeMode = action.payload;
    },

    setSidebarBg: (state, action) => {
      state.activeSidebarBg = action.payload;
    },
    setThemeColor: (state, action) => {
      state.activeTheme = action.payload;
    },
    setTableColor: (state, action) => {
      state.tableColor = action.payload;
    },
  },
});
export const {
  setNavbarBg,
  setDarkTheme,
  setSidebarBg,
  setThemeColor,
  setTableColor,
} = customizerSlice.actions;

export const customizerReducer = customizerSlice.reducer;
